import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  CssBaseline,
  Container,
  AppBar,
  Toolbar,
  Typography,
  Pagination,
  Select,
  MenuItem,
  InputLabel,
  Button,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Switch,
  FormControlLabel,
  TextField,
  FormControl,
} from "@mui/material";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import api from "../services/api";
import { useLocation } from "react-router-dom";
import { Map, Marker, Pin } from "@vis.gl/react-google-maps";
import DatePicker from "react-datepicker";
import moment from "moment-timezone";
import ImageSelector from "../components/ImageSelector"; // Adjust the path as needed

import "react-datepicker/dist/react-datepicker.css";

const INITIAL_CAMERA = {
  center: { lat: 13.75547535254692, lng: 100.49953418649267 },
  zoom: 13,
};

const ViewList = () => {
  const location = useLocation();
  const user = location.state?.user;
  const [selectedLocation, setSelectedLocation] = useState({
    lat: 13.75547535254692,
    lng: 100.49953418649267,
  });
  const mapRef = useRef(null);
  const [cameraProps, setCameraProps] = useState(INITIAL_CAMERA);

  const [wishEvents, setWishEvents] = useState([]);
  const [promiseEvents, setPromiseEvents] = useState([]);
  const [prayerEvents, setPrayerEvents] = useState([]);
  const [fortuneEvents, setFortuneEvents] = useState([]);
  const [eventEvents, setEventEvents] = useState([]);
  const [open, setOpen] = useState(false);
  const [editEvent, setEditEvent] = useState(null);
  const [formValues, setFormValues] = useState({
    title: "",
    detail: "",
    user_id: user.id,
    lati: "",
    longti: "",
    map: "",
    name: "",
    noti: false,
    notiDate: "",
    complete: false,
    type: "",
    summary: "",
    caution: "",
    action: "",
    star: 0,
    selectedMandatory: "taoist",
    isReminderActive: false,
    reminderDuration: "",
    headline: "",
    note1: "",
    note2: "",
    note3: "",
    total: 0,
    num1: 0,
    num1_description: "ดอกไม้ ธูป เทียน น้ำมัน",
    num2: 0,
    num2_description: "ปัจจัย ยอดบริจาค ยอดตู้ต่างๆ",
    num3: 0,
    num3_description: "ภัตตาหาร อาหารคาว หวานต่างๆ",
    num4: 0,
    num4_description: "ของเซ่นไหว้ สิ่งของ",
    num5: 0,
    num5_description: "บริการ ดูดวง เช่าเครื่องลาง ของขลัง",
  });
  const [currentTab, setCurrentTab] = useState(0);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (page = 1, limit = 1000000, search = "") => {
    try {
      const [wishsRes, promisesRes, prayersRes, fortunesRes, eventsRes] =
        await Promise.all([
          api.post(`/wishs/list/${user.id}`, { page, limit, search }),
          api.post(`/promises/list/${user.id}`, { page, limit, search }),
          api.post(`/prayers/list/${user.id}`, { page, limit, search }),
          api.post(`/fortunes/list/${user.id}`, { page, limit, search }),
          api.post(`/events/list/${user.id}`, { page, limit, search }),
        ]);
  
      setWishEvents(wishsRes.data?.data || []);
      setPromiseEvents(promisesRes.data?.data || []);
  
      if (prayersRes.data) {
        const flattenedPrayers = prayersRes.data.data
          .filter((prayerType) => prayerType.type === "บทสวดของฉัน")
          .flatMap((prayerType) =>
            prayerType.data.map((prayer) => ({
              ...prayer,
              typeselect: "บทสวดของฉัน",
            }))
          );
  
        setPrayerEvents(flattenedPrayers);
      }
  
      setFortuneEvents(fortunesRes.data?.data || []);
  
      if (eventsRes.data.data.data) {
        const eventData = Array.isArray(eventsRes.data.data.data)
          ? eventsRes.data.data.data
          : []; // Ensure it's an array
  
        setEventEvents(
          eventData.map((event) => ({
            ...event,
            total:
              (Number(event.num1) || 0) +
              (Number(event.num2) || 0) +
              (Number(event.num3) || 0) +
              (Number(event.num4) || 0) +
              (Number(event.num5) || 0),
          }))
        );
      } else {
        setEventEvents([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  

  
  const addHoursToDate = (date, hours) => {
    return moment.tz(date, "Asia/Bangkok").add(hours, "hours").toDate();
  };
  const handleSave = async () => {
    try {
      const updatedEvent = {
        ...formValues,
        id: editEvent ? editEvent.id : "",
        user_id: user.id,
        noti: formValues.noti ? formValues.noti : false,
        complete: formValues.complete ? formValues.complete : false,
        notiDate: formValues.notiDate ? formValues.notiDate : "",

        lati: selectedLocation.lat ? selectedLocation.lat : false,
        longti: selectedLocation.lng ? selectedLocation.lng : "",
      };

      if (!editEvent) {
        delete updatedEvent.id;
      }

      console.log(formValues);
      console.log(editEvent);

      switch (formValues.type) {
        case "wish":
          editEvent
            ? await api.put(`/wishs/${editEvent.id}`, updatedEvent)
            : await api.post("/wishs", updatedEvent);
          fetchData();
          break;
        case "promise":
          editEvent
            ? await api.put(`/promises/${editEvent.id}`, {
                ...updatedEvent,
                name: updatedEvent.title,
              })
            : await api.post("/promises", {
                ...updatedEvent,
                name: updatedEvent.title,
              });
          fetchData();
          break;
        case "prayer":
          editEvent
            ? await api.put(`/prayers/${editEvent.id}`, {
                ...updatedEvent,
                type: "บทสวดของฉัน",
              })
            : await api.post("/prayers", {
                ...updatedEvent,
                type: "บทสวดของฉัน",
              });
          fetchData();
          break;
        case "fortune":
          editEvent
            ? await api.put(`/fortunes/${editEvent.id}`, {
                ...updatedEvent,
                detail: "",
              })
            : await api.post("/fortunes", {
                ...updatedEvent,
                detail: "",
              });
          fetchData();
          break;
        case "event":
          editEvent
            ? await api.put(`/events/${editEvent.id}`, {
                ...updatedEvent,
                selectedMandatory: updatedEvent.selectedOption,
                total: parseInt(
                  Number(updatedEvent.num1) +
                    Number(updatedEvent.num2) +
                    Number(updatedEvent.num3) +
                    Number(updatedEvent.num4) +
                    Number(updatedEvent.num5)
                ),
              })
            : await api.post("/events", {
                ...updatedEvent,
                selectedMandatory: updatedEvent.selectedOption,
                total: parseInt(
                  Number(updatedEvent.num1) +
                    Number(updatedEvent.num2) +
                    Number(updatedEvent.num3) +
                    Number(updatedEvent.num4) +
                    Number(updatedEvent.num5)
                ),
              });
          fetchData();
          break;
        default:
          break;
      }

      handleClose();
    } catch (error) {
      console.error("Error saving data:", error);
    }
  };

  const handleDelete = async (id, type) => {
    try {
      switch (type) {
        case "wish":
          await api.delete(`/wishs/${id}`);
          fetchData();
          break;
        case "promise":
          await api.delete(`/promises/${id}`);
          fetchData();
          break;
        case "prayer":
          await api.delete(`/prayers/${id}`);
          fetchData();
          break;
        case "fortune":
          await api.delete(`/fortunes/${id}`);
          fetchData();
          break;
        case "event":
          await api.delete(`/events/${id}`);
          fetchData();
          break;
        default:
          break;
      }
    } catch (error) {
      console.error("Error deleting data:", error);
    }
  };

  const handleOpen = (event, type) => {
    setEditEvent(event);
    setFormValues({
      ...event,
      type,
      lati: event.lati,
      longti: event.longti,
      date: addHoursToDate(event.date, -7),
      selectedOption: event.selectedMandatory,
    });
    setSelectedLocation({
      lat: parseFloat(event.lati),
      lng: parseFloat(event.longti),
    });
    setOpen(true);
  };
  const handleImageSelection = (selectedOption) => {
    setFormValues({
      ...formValues,
      selectedOption,
    });
  };
  const handleClose = () => {
    setOpen(false);
    setEditEvent(null);
    setFormValues({
      title: "",
      detail: "",
      user_id: user.id,
      lati: "",
      longti: "",
      map: "",
      name: "",
      noti: false,
      notiDate: "",
      complete: false,
      type: "",
      summary: "",
      caution: "",
      action: "",
      star: 0,
      selectedMandatory: "taoist",
      isReminderActive: false,
      reminderDuration: "",
      headline: "",
      note1: "",
      note2: "",
      note3: "",
      total: 0,
      num1: 0,
      num1_description: "ดอกไม้ รูป เทียน น้ำมัน",
      num2: 0,
      num2_description: "ปัจจัย ยอดบริจาค ยอดตู้ต่างๆ",
      num3: 0,
      num3_description: "ภัตตาหาร อาหารคาว หวานต่างๆ",
      num4: 0,
      num4_description: "ของเซ่นไหว้ สิ่งของ",
      num5: 0,
      num5_description: "บริการ ดูดวง เช่าเครื่องลาง ของขลัง",
    });
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const handleAddEvent = () => {
    setEditEvent(null);
    setFormValues({
      type: ["wish", "promise", "prayer", "fortune", "event"][currentTab],
      num1: 0,
      num1_description: "ดอกไม้ รูป เทียน น้ำมัน",
      num2: 0,
      num2_description: "ปัจจัย ยอดบริจาค ยอดตู้ต่างๆ",
      num3: 0,
      num3_description: "ภัตตาหาร อาหารคาว หวานต่างๆ",
      num4: 0,
      num4_description: "ของเซ่นไหว้ สิ่งของ",
      num5: 0,
      num5_description: "บริการ ดูดวง เช่าเครื่องลาง ของขลัง",
    });
    setOpen(true);
  };

  const handleMapLoad = (mapInstance) => {
    mapRef.current = mapInstance;
  };

  const handleMapDragEnd = () => {
    if (mapRef.current) {
      const center = mapRef.current.getCenter();

      setSelectedLocation({
        lat: center.lat(),
        lng: center.lng(),
      });
    }
  };

  const handleMapClick = (event) => {
    const { detail } = event;
    console.log(detail.latLng);
    // setFormValues({
    //   ...detail,
    //   lati: detail.latLng.lati,
    //   longti: detail.latLng.longti,
    // });
    setSelectedLocation(detail.latLng);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            กิจกรรม
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: "background.default", p: 3 }}
      >
        <Box sx={{ marginBottom: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddEvent}
            startIcon={<AddIcon />}
          >
            เพิ่ม{" "}
            {
              [
                "บทอธิษฐาน",
                "รายการแก้บน",
                "บทสวดมนต์",
                "รายการดูดวง",
                "รายการมู",
              ][currentTab]
            }
          </Button>
        </Box>
        <Box>
          <Tabs
            value={currentTab}
            onChange={handleTabChange}
            aria-label="event tabs"
          >
            <Tab label="บทอธิษฐาน" />
            <Tab label="รายการแก้บน" />
            <Tab label="บทสวดมนต์" />
            <Tab label="รายการดูดวง" />
            <Tab label="รายการมู" />
          </Tabs>
          {currentTab === 0 && (
            <TableContainer component={Paper} sx={{ marginTop: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>หัวข้อ</TableCell>
                    <TableCell>รายละเอียด</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {wishEvents.map((event) => (
                    <TableRow key={event.id}>
                      <TableCell>{event.title}</TableCell>
                      <TableCell>{event.detail}</TableCell>
                      <TableCell>
                        <IconButton
                          color="primary"
                          onClick={() => handleOpen(event, "wish")}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          color="secondary"
                          onClick={() => handleDelete(event.id, "wish")}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {currentTab === 1 && (
            <TableContainer component={Paper} sx={{ marginTop: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>หัวข้อ</TableCell>
                    <TableCell>รายละเอียด</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {promiseEvents.map((event) => (
                    <TableRow key={event.id}>
                      <TableCell>{event.title}</TableCell>
                      <TableCell>{event.detail}</TableCell>
                      <TableCell>
                        <IconButton
                          color="primary"
                          onClick={() => handleOpen(event, "promise")}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          color="secondary"
                          onClick={() => handleDelete(event.id, "promise")}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {currentTab === 2 && (
            <TableContainer component={Paper} sx={{ marginTop: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ประเภท</TableCell>
                    <TableCell>หัวข้อ</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {prayerEvents.map((event) => (
                    <TableRow key={event.id}>
                      <TableCell>บทสวดของฉัน</TableCell>
                      <TableCell>{event.title}</TableCell>
                      <TableCell>
                        <IconButton
                          color="primary"
                          onClick={() => handleOpen(event, "prayer")}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          color="secondary"
                          onClick={() => handleDelete(event.id, "prayer")}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {currentTab === 3 && (
            <TableContainer component={Paper} sx={{ marginTop: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>หัวข้อ</TableCell>
                    <TableCell>ผู้ทำนาย</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {fortuneEvents.map((event) => (
                    <TableRow key={event.id}>
                      <TableCell>{event.summary}</TableCell>
                      <TableCell>{event.name}</TableCell>
                      <TableCell>
                        <IconButton
                          color="primary"
                          onClick={() => handleOpen(event, "fortune")}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          color="secondary"
                          onClick={() => handleDelete(event.id, "fortune")}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          {currentTab === 4 && (
            <TableContainer component={Paper} sx={{ marginTop: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>หัวข้อ</TableCell>
                    <TableCell>การแจ้งเตือน</TableCell>
                    <TableCell>รวม</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {eventEvents.map((event) => (
                    <TableRow key={event.id}>
                      <TableCell>{event.headline}</TableCell>
                      <TableCell>
                        {event.isReminderActive
                          ? "มีแจ้งเตือน"
                          : "ไม่มีแจ้งเตือน"}
                      </TableCell>
                      <TableCell>{event.total}</TableCell>
                      <TableCell>
                        <IconButton
                          color="primary"
                          onClick={() => handleOpen(event, "event")}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          color="secondary"
                          onClick={() => handleDelete(event.id, "event")}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {editEvent ? `Edit ${formValues.type}` : `Create ${formValues.type}`}
        </DialogTitle>
        <DialogContent>
          {formValues.type !== "fortune" && formValues.type !== "event" && (
            <>
              <TextField
                autoFocus
                margin="dense"
                label="หัวข้อ"
                fullWidth
                variant="outlined"
                value={formValues.title || ""}
                onChange={(e) =>
                  setFormValues({ ...formValues, title: e.target.value })
                }
              />

              <TextField
                name="detail"
                label="รายละเอียด"
                variant="outlined"
                value={formValues.detail || ""}
                onChange={(e) =>
                  setFormValues({ ...formValues, detail: e.target.value })
                }
                fullWidth
                multiline
                rows={4}
                sx={{ mt: 2 }}
              />
            </>
          )}
          {formValues.type === "promise" && (
            <>
              <FormControlLabel
                control={
                  <Switch
                    checked={formValues.complete}
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        complete: e.target.checked,
                      })
                    }
                    color="primary"
                  />
                }
                label="แก้บนสำเร็จ"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={formValues.noti}
                    onChange={(e) => {
                      console.log(e.target.checked);

                      setFormValues({
                        ...formValues,
                        noti: e.target.checked,
                        notiDate: "",
                      });
                    }}
                    color="primary"
                  />
                }
                label="การแจ้งเตือน"
              />
              {formValues.noti && (
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel>เลือกช่วงเวลา</InputLabel>
                  <Select
                    value={formValues.notiDate}
                    onChange={(e) =>
                      setFormValues({ ...formValues, notiDate: e.target.value })
                    }
                    label="เลือกช่วงเวลา"
                  >
                    <MenuItem value="" disabled>
                      เลือกช่วงเวลา
                    </MenuItem>
                    <MenuItem value="ทุก 1 เดือน">ทุก 1 เดือน</MenuItem>
                    <MenuItem value="ทุก 3 เดือน">ทุก 3 เดือน</MenuItem>
                    <MenuItem value="ทุก 6 เดือน">ทุก 6 เดือน</MenuItem>
                    <MenuItem value="ทุก 1 ปี">ทุก 1 ปี</MenuItem>
                  </Select>
                </FormControl>
              )}
            </>
          )}

          {/* {formValues.type === "prayer" && (
            <>
              <InputLabel id="type-label" sx={{ mt: 2 }}>
                ประเภท
              </InputLabel>
              <Select
                labelId="type-label"
                name="type"
                value={formValues.typeselect}
                onChange={(e) =>
                  setFormValues({ ...formValues, typeselect: e.target.value })
                }
                fullWidth
              >
                <MenuItem value="" disabled>
                  เลือก
                </MenuItem>
                <MenuItem value="บทสวดของฉัน">บทสวดของฉัน</MenuItem>
               <MenuItem value="ศาสนาฮินดู">ศาสนาฮินดู</MenuItem>
                <MenuItem value="พุทธศาสนา">พุทธศาสนา</MenuItem>
                <MenuItem value="พุทธจีน">พุทธจีน</MenuItem> 
              </Select>
            </>
          )}*/}
          {formValues.type === "fortune" && (
            <>
              <Typography variant="h6">ให้คะแนนการดูดวงของคุณ</Typography>
              <div>
                {[...Array(5)].map((_, index) => (
                  <Button
                    key={index}
                    onClick={() =>
                      setFormValues({ ...formValues, star: index + 1 })
                    }
                    style={{
                      background:
                        index < formValues.star ? "orange" : "transparent",
                    }}
                  >
                    ★
                  </Button>
                ))}
              </div>
              <TextField
                label="สรุปการทำนาย"
                fullWidth
                variant="outlined"
                value={formValues.summary || ""}
                onChange={(e) =>
                  setFormValues({ ...formValues, summary: e.target.value })
                }
                sx={{ mt: 2 }}
              />
              <TextField
                label="เรื่องที่ควรระวัง"
                fullWidth
                variant="outlined"
                value={formValues.caution || ""}
                onChange={(e) =>
                  setFormValues({ ...formValues, caution: e.target.value })
                }
                sx={{ mt: 2 }}
              />
              <TextField
                label="สิ่งที่ควรทำ"
                fullWidth
                variant="outlined"
                value={formValues.action || ""}
                onChange={(e) =>
                  setFormValues({ ...formValues, action: e.target.value })
                }
                sx={{ mt: 2 }}
              />
              <TextField
                label="ชื่อผู้ทำนาย"
                fullWidth
                variant="outlined"
                value={formValues.name || ""}
                onChange={(e) =>
                  setFormValues({ ...formValues, name: e.target.value })
                }
                sx={{ mt: 2 }}
              />

              <FormControlLabel
                control={
                  <Switch
                    checked={formValues.noti}
                    onChange={(e) => {
                      console.log(e.target.checked);

                      setFormValues({
                        ...formValues,
                        noti: e.target.checked,
                      });
                    }}
                    color="primary"
                  />
                }
                label="การแจ้งเตือน"
              />
            </>
          )}
          {formValues.type === "event" && (
            <>
              <ImageSelector
                selectedValue={formValues.selectedOption}
                onChange={handleImageSelection}
              />
              <TextField
                label="Headline"
                value={formValues.headline}
                onChange={(e) =>
                  setFormValues({ ...formValues, headline: e.target.value })
                }
                fullWidth
                margin="normal"
              />
              <TextField
                label="Note 1"
                value={formValues.note1}
                onChange={(e) =>
                  setFormValues({ ...formValues, note1: e.target.value })
                }
                multiline
                rows={3}
                fullWidth
                margin="normal"
              />
              <TextField
                label={formValues.num1_description}
                value={formValues.num1}
                onChange={(e) =>
                  setFormValues({ ...formValues, num1: e.target.value })
                }
                fullWidth
                margin="normal"
                type="number"
              />
              <TextField
                label={formValues.num2_description}
                value={formValues.num2}
                onChange={(e) =>
                  setFormValues({ ...formValues, num2: e.target.value })
                }
                fullWidth
                margin="normal"
                type="number"
              />
              <TextField
                label={formValues.num3_description}
                value={formValues.num3}
                onChange={(e) =>
                  setFormValues({ ...formValues, num3: e.target.value })
                }
                fullWidth
                margin="normal"
                type="number"
              />
              <TextField
                label={formValues.num4_description}
                value={formValues.num4}
                onChange={(e) =>
                  setFormValues({ ...formValues, num4: e.target.value })
                }
                fullWidth
                margin="normal"
                type="number"
              />
              <TextField
                label={formValues.num5_description}
                value={formValues.num5}
                onChange={(e) =>
                  setFormValues({ ...formValues, num5: e.target.value })
                }
                fullWidth
                margin="normal"
                type="number"
              />
              <TextField
                label="รวม"
                disabled
                value={parseInt(
                  Number(formValues.num1) +
                    Number(formValues.num2) +
                    Number(formValues.num3) +
                    Number(formValues.num4) +
                    Number(formValues.num5)
                )}
                fullWidth
                margin="normal"
                type="number"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={formValues.isReminderActive}
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        isReminderActive: e.target.checked,
                      })
                    }
                  />
                }
                label="เตือนกรวดน้ำ"
              />
              {formValues.isReminderActive && (
                <FormControl fullWidth margin="normal">
                  <InputLabel>เตือน</InputLabel>
                  <Select
                    value={formValues.reminderDuration}
                    onChange={(e) =>
                      setFormValues({
                        ...formValues,
                        reminderDuration: e.target.value,
                      })
                    }
                  >
                    <MenuItem value="ใน 12 ชม">ใน 12 ชม</MenuItem>
                    <MenuItem value="ใน 6 ชม">ใน 6 ชม</MenuItem>
                    <MenuItem value="ตั้งเวลาของคุณเอง">
                      ตั้งเวลาของคุณเอง
                    </MenuItem>
                  </Select>
                  <div className="mt-3">
                    {formValues.reminderDuration === "ตั้งเวลาของคุณเอง" && (
                      <div className="form-group">
                        <label htmlFor="timePicker">เลือกช่วงเวลา</label>
                        <DatePicker
                          id="timePicker"
                          className="form-control" // Bootstrap class for styling
                          selected={
                            formValues.date instanceof Date &&
                            !isNaN(formValues.date)
                              ? formValues.date
                              : null
                          } // Ensure it's a valid Date object
                          onChange={(date) => {
                            if (date instanceof Date && !isNaN(date)) {
                              // Check if the date is a valid Date object
                              setFormValues({
                                ...formValues,
                                date: date, // No need to convert to Date again
                              });
                            }
                          }}
                          showTimeSelect
                          showTimeSelectOnly
                          timeIntervals={15} // Adjust the interval as needed
                          timeCaption="Time"
                          dateFormat="h:mm aa" // Format to show only time
                        />
                      </div>
                    )}
                  </div>
                </FormControl>
              )}
            </>
          )}

          {formValues.type === "event" || formValues.type === "promise" ? (
            <>
              <Typography variant="h6" sx={{ mt: 3 }}>
                ตำแหน่งปัจจุบัน: {selectedLocation.lat}, {selectedLocation.lng}
              </Typography>

              <Box sx={{ height: 400, mt: 2 }}>
                <Map
                  ref={mapRef}
                  initialProps={cameraProps}
                  onClick={handleMapClick}
                  onDragEnd={handleMapDragEnd}
                  onLoad={handleMapLoad}
                  style={{ width: "100%", height: "100%" }}
                  defaultZoom={13}
                  defaultCenter={selectedLocation}
                  gestureHandling={"greedy"}
                >
                  <Marker position={selectedLocation}>
                    <Pin scale={1.5} />
                  </Marker>
                </Map>
              </Box>
            </>
          ) : (
            ""
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>ยกเลิก</Button>
          <Button onClick={handleSave}>บักทึก</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ViewList;
